import {
  Avatar as MuiAvatar,
  styled,
  type AvatarTypeMap as MuiAvatarTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';
import {
  useAvatarInitialFontSize,
  useColorStyles,
  useGetSizeStyles
} from './hooks';
import { type AX_AVATAR_SIZE } from './types';

type AxAvatarCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * The size of the avatar.
   * @default 'md'
   */
  size?: AX_AVATAR_SIZE;
  /**
   * The color of the avatar. Follow UIKit avatar color naming convention.
   * @default 'default'
   */
  color?:
    'default' |
    'background-1' |
    'background-2' |
    'background-3' |
    'background-4' |
    'background-5' |
    'background-6' |
    'background-7' |
    'background-8' |
    'background-9' |
    'background-10' |
    'background-11' |
    'background-12';
  variant?: 'circular' | 'rounded' ;
};

export type AxAvatarTypeMap<P = object, D extends React.ElementType = 'div'> = MuiAvatarTypeMap<P & AxAvatarCustomProps, D>;

export type AxAvatarProps<
  D extends ElementType = AxAvatarTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxAvatarTypeMap<P & AxAvatarCustomProps, D>, D>;

const styledOptions = {
  name: 'AxAvatar'
};

const StyledAxAvatar = styled(MuiAvatar, styledOptions)<AxAvatarProps>(({
  size,
  color
}) => {

  return {
    ...useGetSizeStyles(size || 'md'),
    ...useColorStyles(color),
    fontSize: useAvatarInitialFontSize(size)
  };
});

/**
 * Wrapper for MUI Avatar component.
 *
 * ### Links
 * - • [MUI | Avatar Demo](https://mui.com/material-ui/react-avatar/)
 * - • [MUI | Avatar API](https://mui.com/material-ui/api/avatar/)
 * - • [Mockups](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=13101-3206&t=JSLoWpjp8cr2XEEj-0)
 */
export const AxAvatar: MuiOverridableComponent<AxAvatarTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxAvatarProps, ref: Ref<HTMLDivElement>) => {
  const AvatarProps: AxAvatarProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxAvatar { ...AvatarProps } ref={ ref }>
      { children }
    </StyledAxAvatar>
  );
});

export default AxAvatar;


import type { AxAvatarProps } from '@common/modules/react/themes/components/data-display/AxAvatar/AxAvatar';
import { AX_AVATAR_COLORS } from '@common/modules/react/themes/components/data-display/AxAvatar/helper';
import { useTheme } from '@mui/material';
import { type AX_AVATAR_SIZE } from './types';

type sizeStyles = {
  width: number | string;
  height: number | string;
};

export function useGetSizeStyles(size: AX_AVATAR_SIZE = 'md'): sizeStyles {
  const theme = useTheme();

  const sizeStylesMap: { [key in AX_AVATAR_SIZE]: sizeStyles } = {
    xs: {
      width: theme.uiKit.avatar.size.xs,
      height: theme.uiKit.avatar.size.xs
    },
    sm: {
      width: theme.uiKit.avatar.size.sm,
      height: theme.uiKit.avatar.size.sm
    },
    md: {
      width: theme.uiKit.avatar.size.md,
      height: theme.uiKit.avatar.size.md
    },
    lg: {
      width: theme.uiKit.avatar.size.lg,
      height: theme.uiKit.avatar.size.lg
    },
    xl: {
      width: theme.uiKit.avatar.size.xl,
      height: theme.uiKit.avatar.size.xl
    }
  };

  return sizeStylesMap[size];
}

export const useColorStyles = (color: AxAvatarProps['color'] = 'default') => {
  const theme = useTheme();
  const styles: {
    [key: string]: {
      backgroundColor: string
    }
  } = {
    default: {
      backgroundColor: theme.uiKit.colorGrey30
    }
  };

  AX_AVATAR_COLORS.map((axColor, i) => {
    styles[`background-${ i + 1 }`] = {
      backgroundColor: axColor
    };
  });

  return styles[color];
};

export function useAvatarInitialFontSize(avatarSize: AxAvatarProps['size'] | undefined = 'md'): string {
  const fontSizeMap = {
    xs: '0.8rem',
    sm: '1rem',
    md: '1.4rem',
    lg: '1.8rem',
    xl: '2.4rem'
  };

  return fontSizeMap[avatarSize];
}
